var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.$store.state.app.roleName !== 'viewer' && !_vm.$can('manage', 'special') && !_vm.$can('SAL', '') && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'primary',
      "to": {
        name: _vm.enableCreateForm,
        params: {
          previous_link: {
            name: 'dashboard-monitoring-rrv-tbbm'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Buat Rencana Kedatangan Kapal TBBM ")], 1)])]) : _vm._e()], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    ref: "goodTable",
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "is-loading": _vm.yo_isLoading,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "row-style-class": _vm.rowStyleClassFn,
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_vm._v(" Kegiatan "), _c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('strong', [_vm._v(_vm._s(props.row.label))])]), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row$form_tug_b, _props$row$form_tug_b2, _props$row$form_tug_b3, _props$row$form_tug_b4, _props$row$form_tug_b5, _props$row$form_tug_b6, _props$row$form_tug_b7, _props$row$form_tug_b8, _props$row$form_tug_b9, _props$row$form_tug_b10, _props$row$form_tug_b11, _props$row$form_tug_b12, _props$row$form_tug_b13, _props$row$form_tug_b14, _props$row$form_tug_b15, _props$row$form_tug_b16, _props$row$form_tug_b17, _props$row$form_tug_b18, _props$row$form_tug_b19, _props$row$form_tug_b20, _props$row$form_tug_b21, _props$row$form_tug_b22;
        return [_c('transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.pemilik ? _c('span', [props.column.field === 'form_tbbm.form_status' ? _c('span', [_c('h6', [_c('b-row', [_c('b-col', [_c('strong', [_c('b-badge', {
          staticClass: "d-block py-1",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(_vm.ftbStatus(props.row))
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.ftbStatus(props.row));
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.ftbStatus(props.row))
          }
        })])], 1)])], 1)], 1), props.row.no_spog_tb && !props.row.sandar_end_time ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1 cursor-pointer",
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body",
          on: {
            "click": function click($event) {
              return _vm.copyText('DIIJINKAN SANDAR');
            }
          }
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" DIIJINKAN SANDAR")
          }
        })], 1)])])], 1) : _vm._e()]) : props.column.field === 'form_tbbm.dm_river_boat.boat_name' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.tug_boat.name_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.tug_boat.name_tug_boat) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Nomor PKK")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.tug_boat.no_pkk_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.tug_boat.no_pkk_tug_boat) + " ")])], 1)]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('strong', [_vm._v(" Kegiatan ")])])]), _c('span', [_c('strong', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.kegiatan);
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.row.kegiatan)
          }
        })])], 1)])]) : props.column.field === 'keterangan' ? _c('span', [_c('span', [_vm._v("[TugBoat] ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Dokumen")])]), _c('span', [_c('small', [_c('strong', [_vm._v(" RPKRO : ")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": "click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_rpkro_tb);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_rpkro_tb) + " ")]), !props.row.no_rpkro_tb ? _c('b-spinner', {
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }) : _vm._e()], 1)]), _c('br'), _c('span', [_c('small', [_c('strong', [_vm._v(" SPOG : ")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": "click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_spog_tb);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_spog_tb) + " ")]), !props.row.no_spog_tb ? _c('b-spinner', {
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }) : _vm._e()], 1)])]) : props.column.field === 'form_tbbm.rencana_sandar' ? _c('span', [_c('strong', [_c('span', [_vm._v(" ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Sandar")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.dateHumanReadAble(props.row.rencana_sandar));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.rencana_sandar)) + " ")], 1)], 1)]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": _vm.ftbStatus(props.row) !== 'KAPAL BERANGKAT' ? _vm.ftbStatus(props.row) === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "block": "",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-view',
              params: {
                id: props.row.id,
                api: 'form_tug_boat',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-tbbm'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        })], 1)], 1), _c('br'), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "sm",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_tbbm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-tbbm'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        })], 1), _c('br'), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" #" + _vm._s(props.row.id))])])])], 1) : _c('strong', [_vm._v("tug boat")])]) : _c('span', [props.column.field === 'form_tbbm.form_status' ? _c('span', [_c('h6', [_c('b-row', [_c('b-col', [_c('strong', [_c('b-badge', {
          staticClass: "d-block py-1 cursor-pointer",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(props.row.form_tbbm.form_status)
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_tbbm.form_status);
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.row.form_tbbm.form_status)
          }
        })])], 1)])], 1)], 1), props.row.form_tbbm.jenis_kapal === 'Barge' ? _c('span', [props.row.form_tbbm.no_spog && (_props$row$form_tug_b = props.row.form_tug_boat) !== null && _props$row$form_tug_b !== void 0 && _props$row$form_tug_b.no_spog_tb && props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT' ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1 cursor-pointer",
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body",
          on: {
            "click": function click($event) {
              return _vm.copyText('DIIJINKAN SANDAR');
            }
          }
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" DIIJINKAN SANDAR")
          }
        })], 1)])])], 1) : _vm._e()]) : _c('span', [props.row.form_tbbm.no_spog && props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT' ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body",
          on: {
            "click": function click($event) {
              return _vm.copyText('DIIJINKAN SANDAR');
            }
          }
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" DIIJINKAN SANDAR")
          }
        })], 1)])])], 1) : _vm._e()]), props.row.form_tbbm.no_pindah ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "info",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" KAPAL PERNAH PINDAH")
          }
        })], 1)])])], 1) : _vm._e(), props.row.form_tbbm.kegiatan_start_time_p1 && !props.row.form_tbbm.kegiatan_end_time_p1 ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" KAPAL SEDANG BERKEGIATAN")
          }
        })], 1)])])], 1) : _vm._e(), props.row.form_tbbm.kegiatan_end_time_p1 && !props.row.form_tbbm.sandar_end_time_p1 ? _c('h5', [_c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(" KAPAL SELESAI BERKEGIATAN")
          }
        })], 1)])])], 1) : _vm._e(), _vm.checkIsNoRpkro(props.row) === true && props.row.form_tbbm.no_do_bl === 'no_do_bl' ? _c('small', [_c('span', [_c('b-spinner', {
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }), _c('strong', [_vm._v(" Verification ( Warning )")]), _vm._v(": "), _c('ol', [_c('li', [_vm._v(" Admin SAL Sedang approval kegiatan bunker ")])])], 1)]) : _vm._e(), _vm.showWarning(props.row) && _vm.checkIsNoRpkro(props.row) === false ? _c('small', [_c('span', [_c('b-spinner', {
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }), _c('strong', [_vm._v(" Verification ( Warning )")]), _vm._v(": "), _c('ol', [_vm.$can('TBBM', '') ? _c('span', [!props.row.form_tbbm.no_spog ? _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": 'light-warning'
          }
        }, [_vm._v(" Main Boat (" + _vm._s(props.row.form_tbbm.dm_river_boat.boat_name) + ") ")]) : _vm._e(), !props.row.form_tbbm.no_rpkro ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Admin SAL Sedang menerbitkan RPKRO')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !props.row.form_tbbm.no_spog ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), props.row.form_tbbm.jenis_kapal === 'Barge' ? _c('span', [!((_props$row$form_tug_b2 = props.row.form_tug_boat) !== null && _props$row$form_tug_b2 !== void 0 && _props$row$form_tug_b2.no_spog_tb) ? _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": 'light-warning'
          }
        }, [_vm._v(" Tug Boat (" + _vm._s(((_props$row$form_tug_b3 = props.row.form_tug_boat) === null || _props$row$form_tug_b3 === void 0 ? void 0 : (_props$row$form_tug_b4 = _props$row$form_tug_b3.tug_boat) === null || _props$row$form_tug_b4 === void 0 ? void 0 : _props$row$form_tug_b4.name_tug_boat) || '') + ") ")]) : _vm._e(), !((_props$row$form_tug_b5 = props.row.form_tug_boat) !== null && _props$row$form_tug_b5 !== void 0 && _props$row$form_tug_b5.no_rpkro_tb) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Admin SAL Sedang menerbitkan RPKRO')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !((_props$row$form_tug_b6 = props.row.form_tug_boat) !== null && _props$row$form_tug_b6 !== void 0 && _props$row$form_tug_b6.no_spog_tb) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') ? _c('div', [_c('hr'), _c('small', [_c('i', [_vm._v("NB: For Tenant")])]), _c('hr')]) : _vm._e()], 1) : _vm._e(), _vm.$can('SAL', '') ? _c('span', [!props.row.form_tbbm.no_rpkro_tb || !props.row.form_tbbm.no_spog_tb || !props.row.form_tbbm.kegiatan_start_time ? _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": 'light-warning'
          }
        }, [_vm._v(" Main Boat (" + _vm._s(props.row.form_tbbm.dm_river_boat.boat_name) + ") ")]) : _vm._e(), !props.row.form_tbbm.no_rpkro ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor RPKRO')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !props.row.form_tbbm.no_spog ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !props.row.form_tbbm.kegiatan_start_time_p1 ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi waktu mulai kegiatan')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), props.row.form_tbbm.kegiatan_start_time_p1 && !props.row.form_tbbm.kegiatan_end_time_p1 ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi waktu selesai kegiatan')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), props.row.form_tbbm.jenis_kapal === 'Barge' && (_props$row$form_tug_b7 = props.row.form_tug_boat) !== null && _props$row$form_tug_b7 !== void 0 && (_props$row$form_tug_b8 = _props$row$form_tug_b7.tug_boat) !== null && _props$row$form_tug_b8 !== void 0 && _props$row$form_tug_b8.name_tug_boat ? _c('span', [!((_props$row$form_tug_b9 = props.row.form_tug_boat) !== null && _props$row$form_tug_b9 !== void 0 && _props$row$form_tug_b9.no_rpkro_tb) || !((_props$row$form_tug_b10 = props.row.form_tug_boat) !== null && _props$row$form_tug_b10 !== void 0 && _props$row$form_tug_b10.no_spog_tb) || !((_props$row$form_tug_b11 = props.row.form_tug_boat) !== null && _props$row$form_tug_b11 !== void 0 && (_props$row$form_tug_b12 = _props$row$form_tug_b11.tug_boat) !== null && _props$row$form_tug_b12 !== void 0 && _props$row$form_tug_b12.kegiatan_start_time_tb) ? _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": 'light-warning'
          }
        }, [_vm._v(" Tug Boat (" + _vm._s(((_props$row$form_tug_b13 = props.row.form_tug_boat) === null || _props$row$form_tug_b13 === void 0 ? void 0 : (_props$row$form_tug_b14 = _props$row$form_tug_b13.tug_boat) === null || _props$row$form_tug_b14 === void 0 ? void 0 : _props$row$form_tug_b14.name_tug_boat) || '') + ") ")]) : _vm._e(), !((_props$row$form_tug_b15 = props.row.form_tug_boat) !== null && _props$row$form_tug_b15 !== void 0 && _props$row$form_tug_b15.no_rpkro_tb) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor RPKRO')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !((_props$row$form_tug_b16 = props.row.form_tug_boat) !== null && _props$row$form_tug_b16 !== void 0 && _props$row$form_tug_b16.no_spog_tb) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), !((_props$row$form_tug_b17 = props.row.form_tug_boat) !== null && _props$row$form_tug_b17 !== void 0 && (_props$row$form_tug_b18 = _props$row$form_tug_b17.tug_boat) !== null && _props$row$form_tug_b18 !== void 0 && _props$row$form_tug_b18.kegiatan_start_time_tb_p1) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi waktu kegiatan')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e(), (_props$row$form_tug_b19 = props.row.form_tug_boat) !== null && _props$row$form_tug_b19 !== void 0 && (_props$row$form_tug_b20 = _props$row$form_tug_b19.tug_boat) !== null && _props$row$form_tug_b20 !== void 0 && _props$row$form_tug_b20.kegiatan_start_time_tb_p1 && !((_props$row$form_tug_b21 = props.row.form_tug_boat) !== null && _props$row$form_tug_b21 !== void 0 && (_props$row$form_tug_b22 = _props$row$form_tug_b21.tug_boat) !== null && _props$row$form_tug_b22 !== void 0 && _props$row$form_tug_b22.kegiatan_end_time_tb_p1) ? _c('li', [_c('span', {
          domProps: {
            "innerHTML": _vm._s('Belum mengisi waktu selesai kegiatan')
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": 'XCircleIcon'
          }
        })], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') ? _c('div', [_c('hr'), _c('small', [_c('i', [_vm._v("NB: For Admin SAL")])]), _c('hr')]) : _vm._e()], 1) : _vm._e()])], 1)]) : _vm._e()]) : props.column.field === 'form_tbbm.dm_river_boat.boat_name' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_tbbm.dm_river_boat.boat_name);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.form_tbbm.dm_river_boat.boat_name) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Nomor PKK")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_tbbm.no_pkk);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.form_tbbm.no_pkk) + " ")])], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Kegiatan")])]), _c('span', [_c('strong', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": props.row.form_tbbm.kegiatan == 'BONGKAR' ? 'light-primary' : props.row.form_tbbm.kegiatan == 'TAMBAT' ? 'light-danger' : 'light-dark'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText("".concat(props.row.form_tbbm.kegiatan).concat(props.row.kegiatan_tambahan ? ", ".concat(props.row.kegiatan_tambahan) : ""));
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s("".concat(props.row.form_tbbm.kegiatan).concat(props.row.kegiatan_tambahan ? ", ".concat(props.row.kegiatan_tambahan) : ""))
          }
        })])], 1)])])]) : props.column.field === 'keterangan' ? _c('span', [_c('span', [_vm._v("[" + _vm._s(props.row.form_tbbm.jenis_kapal) + "] ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Pemilik")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": props.row.form_tbbm.pemilik === 'TEM' ? 'light-primary' : 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_tbbm.pemilik);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', [_vm._v(" " + _vm._s(props.row.form_tbbm.pemilik) + " "), props.row.pemilik_dua ? _c('span', [_vm._v(" , " + _vm._s(props.row.pemilik_dua) + " ")]) : _vm._e()])], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v(" Approval " + _vm._s(props.row.form_tbbm.keterangan_batal_approval ? 'Dibatalkan' : '') + " ")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": props.row.form_tbbm.no_do_bl ? 'light-success' : 'light-warning'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.form_tbbm.no_do_bl ? 'telah disetujui' : 'belum disetujui');
            }
          }
        }, [props.row.form_tbbm.no_do_bl ? _c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "FileIcon"
          }
        }) : _vm._e(), !props.row.form_tbbm.no_do_bl ? _c('b-spinner', {
          staticClass: "mr-1",
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }) : _vm._e(), _c('strong', [_c('span', {
          domProps: {
            "textContent": _vm._s(!props.row.form_tbbm.no_do_bl ? props.row.form_tbbm.keterangan_batal_approval ? props.row.form_tbbm.keterangan_batal_approval : 'Belum disetujui' : 'Telah disetujui')
          }
        })]), !props.row.form_tbbm.no_do_bl ? _c('b-spinner', {
          staticClass: "ml-1",
          attrs: {
            "variant": "warning",
            "type": "grow",
            "small": ""
          }
        }) : _vm._e()], 1)], 1) : props.column.field === 'form_tbbm.rencana_sandar' ? _c('span', [_c('strong', [_c('span', [_vm._v(" ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Sandar")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": props.row.form_tbbm.pemilik === 'TEM' ? 'light-primary' : 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.dateHumanReadAble(props.row.form_tbbm.rencana_sandar));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.form_tbbm.rencana_sandar)) + " ")], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Asal Kapal")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": props.row.form_tbbm.pemilik === 'TEM' ? 'light-primary' : 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.asalKapal(props.row.form_tbbm.dm_asal_kapal));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "MapPinIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.asalKapal(props.row.form_tbbm.dm_asal_kapal)) + " ")], 1)], 1)]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT' ? props.row.form_tbbm.form_status === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "to": {
              name: _vm.enableViewForm,
              params: {
                id: props.row.id,
                api: 'form_chain_tbbm',
                kegiatanDetail: _vm.currentlyKegiatanKapal(props.row.form_tbbm),
                previous_link: {
                  name: 'dashboard-monitoring-rrv-tbbm'
                }
              }
            },
            "size": "sm",
            "block": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": props.row.form_tbbm.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : 'PenToolIcon',
            "size": "25"
          }
        })], 1), _c('br'), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "sm",
            "to": {
              name: 'dashboard-monitoring-rrv-tbbm-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_tbbm',
                previous_link: {
                  name: 'dashboard-monitoring-rrv-tbbm'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        })], 1), _c('br'), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" #" + _vm._s(props.row.id))])])])], 1)]) : _c('span', [_c('strong', [_vm._v(_vm._s(props.formattedRow[props.column.field]))])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }