<template>
  <YoTable
    ref-specify="YoTable"
    :columns="columns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    enable-create-form="dashboard-monitoring-rrv-tbbm-create"
    enable-view-form="dashboard-monitoring-rrv-tbbm-view"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@views/pages/monitoring/jalur-sungai/tbbm/YoTable.vue'

export default {
  name: 'RiverRouteHuluMigasTable',
  components: {
    YoTable
  },
  data() {
    return {
      columns: [
        {
          label: 'Status',
          field: 'form_tbbm.form_status'
        },
        {
          label: 'Kapal',
          field: 'form_tbbm.dm_river_boat.boat_name'
        },
        {
          label: 'Document',
          field: 'keterangan',
          formatFn: this.dateHumanReadAble
        },
        {
          label: 'Keterangan',
          field: 'form_tbbm.rencana_sandar',
          formatFn: this.dateHumanReadAble
        },
        {
          label: 'Details',
          field: 'action'
        }
      ]
    }
  },
  created() {
    // this.$root.$moment.locale('id')
  },
  methods: {
    moment,
    resolveFetchPayload() {
      return {
        API_SAL: 'form_chain_tbbm',
        serverParams: {
          sort: {
            type: 'DESC',
            field: 'created_at'
          },
          perPage: 7 // override
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    resolveFetchResponse(response) {
      return response.data.form_chain_tbbms
    }
  }
}
</script>
