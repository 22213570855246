<template>
  <div class="monitoring">
    <b-row>
      <b-col md="8" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!--  -->
          </div>
        </b-form-group>
      </b-col>
      <b-col class="text-right mb-2" md="4" cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'primary'"
            v-if="
              $store.state.app.roleName !== 'viewer' &&
              !$can('manage', 'special') &&
              !$can('SAL', '') &&
              $store.state.app.roleName !== 'jetty man'
            "
            :to="{
              name: enableCreateForm,
              params: {
                previous_link: {
                  name: 'dashboard-monitoring-rrv-tbbm'
                }
              }
            }"
          >
            <!-- @click="newData(status)" -->
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Buat Rencana Kedatangan Kapal TBBM
              </strong>
            </span>
          </b-button>
          <!-- <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'warning'"
          >
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Export to Excel (
                {{
                  selectedRows.length === 0
                    ? 'ALL Rows'
                    : `Only Row Selected (${selectedRows.length})`
                }}
                )
              </strong></span
            >
          </b-button> -->
        </div>
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <vue-good-table
      ref="goodTable"
      :key="yoRender"
      mode="remote"
      class="yo-good-table"
      :total-rows="total_records"
      :columns="columns"
      :is-loading="yo_isLoading"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder:
          'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      }"
      @on-search="onSearchTable"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: thereIsGroup
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered table-hover"
      :row-style-class="rowStyleClassFn"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!--
         -->
      <template slot="table-header-row" slot-scope="props">
        Kegiatan
        <BBadge variant="primary" class="mr-1">
          <strong>{{ props.row.label }}</strong>
        </BBadge>
        <BBadge variant="secondary">
          Jumlah Data : {{ props.row.children.length }}
        </BBadge>
      </template>
      <template slot="table-row" slot-scope="props">
        <transition mode="out-in">
          <!-- Column: Status -->
          <!-- STATUS -->

          <!-- Column: Common -->
          <!-- form_tug_boat -->
          <span v-if="props.row?.pemilik">
            <!-- {{ props.row?.pemilik }} -->
            <span v-if="props.column.field === 'form_tbbm.form_status'">
              <!-- {{ dateHumanReadAble(props.row.created_at) }} -->
              <h6>
                <b-row>
                  <b-col>
                    <strong>
                      <b-badge
                        pill
                        :variant="formStatusVariant(ftbStatus(props.row))"
                        class="d-block py-1"
                        @click="copyText(ftbStatus(props.row))"
                      >
                        <span v-text="ftbStatus(props.row)" />
                      </b-badge>
                    </strong>
                  </b-col>
                </b-row>
              </h6>
              <h5 v-if="props.row.no_spog_tb && !props.row.sandar_end_time">
                <b-alert variant="primary" show class="mt-1 cursor-pointer">
                  <div class="alert-body" @click="copyText('DIIJINKAN SANDAR')">
                    <strong>
                      <feather-icon icon="AnchorIcon" class="mr-25" />
                      <span v-text="` DIIJINKAN SANDAR`" />
                    </strong>
                  </div>
                </b-alert>
              </h5>
            </span>
            <span
              v-else-if="
                props.column.field === 'form_tbbm.dm_river_boat.boat_name'
              "
            >
              <strong>
                <span
                  class="cursor-pointer"
                  @click="copyText(props.row.tug_boat.name_tug_boat)"
                >
                  {{ props.row.tug_boat.name_tug_boat }}
                </span>
                <div class="divider">
                  <div class="divider-text">Nomor PKK</div>
                </div>
                <span>
                  <b-badge
                    href="#"
                    :variant="'light-primary'"
                    @click="copyText(props.row.tug_boat.no_pkk_tug_boat)"
                    class="d-block"
                  >
                    {{ props.row.tug_boat.no_pkk_tug_boat }}
                  </b-badge>
                </span>
              </strong>
              <div class="divider">
                <div class="divider-text">
                  <strong> Kegiatan </strong>
                </div>
              </div>
              <span>
                <strong>
                  <b-badge
                    href="#"
                    :variant="'light-primary'"
                    @click="copyText(props.row.kegiatan)"
                    class="d-block"
                  >
                    <span v-text="props.row.kegiatan" />
                  </b-badge>
                </strong>
              </span>
            </span>
            <span v-else-if="props.column.field === 'keterangan'">
              <span>[TugBoat]&nbsp;</span>
              <div class="divider">
                <div class="divider-text">Dokumen</div>
              </div>
              <span>
                <small
                  ><strong> RPKRO : </strong>
                  <span
                    v-b-tooltip.hover
                    title="click to copy"
                    @click="copyText(props.row.no_rpkro_tb)"
                  >
                    {{ props.row.no_rpkro_tb }}
                  </span>
                  <b-spinner
                    v-if="!props.row.no_rpkro_tb"
                    variant="warning"
                    class=""
                    type="grow"
                    small
                /></small>
              </span>
              <br />
              <span>
                <small>
                  <strong> SPOG : </strong>
                  <span
                    v-b-tooltip.hover
                    title="click to copy"
                    @click="copyText(props.row.no_spog_tb)"
                  >
                    {{ props.row.no_spog_tb }}
                  </span>
                  <b-spinner
                    v-if="!props.row.no_spog_tb"
                    variant="warning"
                    class=""
                    type="grow"
                    small
                  />
                </small>
              </span>
            </span>
            <span v-else-if="props.column.field === 'form_tbbm.rencana_sandar'">
              <strong>
                <span>&nbsp;</span>
                <div class="divider">
                  <div class="divider-text">Rencana Sandar</div>
                </div>
                <b-badge
                  href="#"
                  :variant="'light-primary'"
                  @click="copyText(dateHumanReadAble(props.row.rencana_sandar))"
                  class="d-block"
                >
                  <feather-icon icon="CalendarIcon" class="mr-50" />
                  {{ dateHumanReadAble(props.row.rencana_sandar) }}
                  <!-- <feather-icon
                    class="cursor-pointer mr-1"
                    :icon="
                      dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                        ? 'SunIcon'
                        : 'MoonIcon'
                    "
                  /> -->
                </b-badge>
              </strong>
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  :variant="
                    ftbStatus(props.row) !== 'KAPAL BERANGKAT'
                      ? ftbStatus(props.row) === 'KEGIATAN DIBATALKAN'
                        ? 'outline-danger'
                        : 'outline-warning'
                      : 'outline-success'
                  "
                  block
                  :to="{
                    name: 'dashboard-monitoring-rrv-tug-boat-view',
                    params: {
                      id: props.row.id,
                      api: 'form_tug_boat',
                      previous_link: {
                        name: 'dashboard-monitoring-rrv-tbbm'
                      }
                    }
                  }"
                  size="sm"
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="'PenToolIcon'"
                    size="25"
                  />
                  <!-- Realisasikan -->
                </b-button>
              </span>
              <br />
              <b-button
                class="mt-1"
                variant="outline-primary"
                block
                size="sm"
                :to="{
                  name: 'dashboard-monitoring-rrv-tug-boat-summary',
                  params: {
                    id: props.row.id,
                    api: 'form_chain_tbbm',
                    previous_link: {
                      name: 'dashboard-monitoring-rrv-tbbm'
                    }
                  }
                }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="ActivityIcon"
                  size="25"
                />
                <!-- Summary -->
              </b-button>
              <br />
              <b-badge pill class="d-block" :variant="'light-primary'">
                <strong>
                  <span> #{{ props.row.id }}</span>
                </strong>
              </b-badge>
            </span>
            <strong v-else>tug boat</strong>
          </span>

          <!-- form_chain_tbbm -->
          <span v-else>
            <span v-if="props.column.field === 'form_tbbm.form_status'">
              <!-- {{ dateHumanReadAble(props.row.created_at) }} -->
              <h6>
                <b-row>
                  <b-col>
                    <strong>
                      <b-badge
                        pill
                        :variant="
                          formStatusVariant(props.row.form_tbbm.form_status)
                        "
                        class="d-block py-1 cursor-pointer"
                        @click="copyText(props.row.form_tbbm.form_status)"
                      >
                        <span v-text="props.row.form_tbbm.form_status" />
                      </b-badge>
                    </strong>
                  </b-col>
                </b-row>
              </h6>
              <span v-if="props.row.form_tbbm.jenis_kapal === 'Barge'">
                <!-- barge -->
                <h5
                  v-if="
                    props.row.form_tbbm.no_spog &&
                    props.row.form_tug_boat?.no_spog_tb &&
                    props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT'
                  "
                >
                  <b-alert variant="primary" show class="mt-1 cursor-pointer">
                    <div
                      class="alert-body"
                      @click="copyText('DIIJINKAN SANDAR')"
                    >
                      <strong>
                        <feather-icon icon="AnchorIcon" class="mr-25" />
                        <span v-text="` DIIJINKAN SANDAR`" />
                      </strong>
                    </div>
                  </b-alert>
                </h5>
                <!-- barge -->
              </span>
              <span v-else>
                <!-- barge -->
                <h5
                  v-if="
                    props.row.form_tbbm.no_spog &&
                    props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT'
                  "
                >
                  <b-alert variant="primary" show class="mt-1">
                    <div
                      class="alert-body"
                      @click="copyText('DIIJINKAN SANDAR')"
                    >
                      <strong>
                        <feather-icon icon="AnchorIcon" class="mr-25" />
                        <span v-text="` DIIJINKAN SANDAR`" />
                      </strong>
                    </div>
                  </b-alert>
                </h5>
                <!-- barge -->
              </span>
              <h5 v-if="props.row.form_tbbm.no_pindah">
                <b-alert variant="info" show class="mt-1">
                  <div class="alert-body">
                    <strong>
                      <feather-icon icon="AnchorIcon" class="mr-25" />
                      <span v-text="` KAPAL PERNAH PINDAH`" />
                    </strong>
                  </div>
                </b-alert>
              </h5>
              <h5
                v-if="
                  props.row.form_tbbm.kegiatan_start_time_p1 &&
                  !props.row.form_tbbm.kegiatan_end_time_p1
                "
              >
                <b-alert variant="warning" show class="mt-1">
                  <div class="alert-body">
                    <strong>
                      <feather-icon icon="AnchorIcon" class="mr-25" />
                      <span v-text="` KAPAL SEDANG BERKEGIATAN`" />
                    </strong>
                  </div>
                </b-alert>
              </h5>
              <h5
                v-if="
                  props.row.form_tbbm.kegiatan_end_time_p1 &&
                  !props.row.form_tbbm.sandar_end_time_p1
                "
              >
                <b-alert variant="success" show class="mt-1">
                  <div class="alert-body">
                    <strong>
                      <feather-icon icon="AnchorIcon" class="mr-25" />
                      <span v-text="` KAPAL SELESAI BERKEGIATAN`" />
                    </strong>
                  </div>
                </b-alert>
              </h5>
              <small v-if="checkIsNoRpkro(props.row) === true && props.row.form_tbbm.no_do_bl === 'no_do_bl'">
                <span>
                  <b-spinner variant="warning" class="" type="grow" small />
                  <strong> Verification ( Warning )</strong>:
                  <ol>
                    <li>
                      Admin SAL Sedang approval kegiatan bunker
                    </li>
                  </ol>
                </span>
              </small>
              <small v-if="showWarning(props.row) && checkIsNoRpkro(props.row) === false">
                <span>
                  <b-spinner variant="warning" class="" type="grow" small />
                  <strong> Verification ( Warning )</strong>:
                  <ol>
                    <span v-if="$can('TBBM', '')">
                      <!-- show only for admin sal -->
                      <b-badge
                        :variant="'light-warning'"
                        class="d-block"
                        v-if="!props.row.form_tbbm.no_spog"
                      >
                        Main Boat ({{
                          props.row.form_tbbm.dm_river_boat.boat_name
                        }})
                      </b-badge>
                      <li v-if="!props.row.form_tbbm.no_rpkro">
                        <span v-html="'Admin SAL Sedang menerbitkan RPKRO'">
                        </span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="!props.row.form_tbbm.no_spog">
                        <span v-html="'Belum mengisi Nomor SPOG'"></span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <!-- show only for tug boat -->

                      <span v-if="props.row.form_tbbm.jenis_kapal === 'Barge'">
                        <b-badge
                          :variant="'light-warning'"
                          class="d-block"
                          v-if="!props.row.form_tug_boat?.no_spog_tb"
                        >
                          Tug Boat ({{
                            props.row.form_tug_boat?.tug_boat?.name_tug_boat ||
                            ''
                          }})
                        </b-badge>
                        <li v-if="!props.row.form_tug_boat?.no_rpkro_tb">
                          <span v-html="'Admin SAL Sedang menerbitkan RPKRO'">
                          </span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <li v-if="!props.row.form_tug_boat?.no_spog_tb">
                          <span v-html="'Belum mengisi Nomor SPOG'"></span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <!-- show only for tug boat -->
                      </span>
                      <!-- show only for admin sal -->
                      <div v-if="$can('manage', 'all')">
                        <hr/>
                        <small><i>NB: For Tenant</i></small>
                        <hr/>
                      </div>
                    </span>
                    <span v-if="$can('SAL', '')">
                      <!-- show only for admin sal -->
                      <b-badge
                        :variant="'light-warning'"
                        class="d-block"
                        v-if="
                          !props.row.form_tbbm.no_rpkro_tb ||
                          !props.row.form_tbbm.no_spog_tb ||
                          !props.row.form_tbbm.kegiatan_start_time
                        "
                      >
                        Main Boat ({{
                          props.row.form_tbbm.dm_river_boat.boat_name
                        }})
                      </b-badge>
                      <li v-if="!props.row.form_tbbm.no_rpkro">
                        <span
                          v-html="
                            'Belum mengisi Nomor RPKRO'
                          "
                        ></span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="!props.row.form_tbbm.no_spog">
                        <span v-html="'Belum mengisi Nomor SPOG'"></span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li v-if="!props.row.form_tbbm.kegiatan_start_time_p1">
                        <span
                          v-html="'Belum mengisi waktu mulai kegiatan'"
                        ></span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>
                      <li
                        v-if="
                          props.row.form_tbbm.kegiatan_start_time_p1 &&
                          !props.row.form_tbbm.kegiatan_end_time_p1
                        "
                      >
                        <span
                          v-html="'Belum mengisi waktu selesai kegiatan'"
                        ></span>
                        <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                      </li>

                      <span
                        v-if="
                          props.row.form_tbbm.jenis_kapal === 'Barge' &&
                          props.row.form_tug_boat?.tug_boat?.name_tug_boat
                        "
                      >
                        <!-- show only for tug boat -->
                        <b-badge
                          :variant="'light-warning'"
                          class="d-block"
                          v-if="
                            !props.row.form_tug_boat?.no_rpkro_tb ||
                            !props.row.form_tug_boat?.no_spog_tb ||
                            !props.row.form_tug_boat?.tug_boat
                              ?.kegiatan_start_time_tb
                          "
                        >
                          Tug Boat ({{
                            props.row.form_tug_boat?.tug_boat?.name_tug_boat ||
                            ''
                          }})
                        </b-badge>
                        <li v-if="!props.row.form_tug_boat?.no_rpkro_tb">
                          <span
                            v-html="
                              'Belum mengisi Nomor RPKRO'
                            "
                          ></span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <li v-if="!props.row.form_tug_boat?.no_spog_tb">
                          <span v-html="'Belum mengisi Nomor SPOG'"></span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <li
                          v-if="
                            !props.row.form_tug_boat?.tug_boat
                              ?.kegiatan_start_time_tb_p1
                          "
                        >
                          <span v-html="'Belum mengisi waktu kegiatan'"></span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <li
                          v-if="
                            props.row.form_tug_boat?.tug_boat
                              ?.kegiatan_start_time_tb_p1 &&
                            !props.row.form_tug_boat?.tug_boat
                              ?.kegiatan_end_time_tb_p1
                          "
                        >
                          <span
                            v-html="'Belum mengisi waktu selesai kegiatan'"
                          ></span>
                          <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                        </li>
                        <!-- show only for tug boat -->
                      </span>
                      <!-- show only for admin sal -->
                      <div v-if="$can('manage', 'all')">
                        <hr/>
                        <small><i>NB: For Admin SAL</i></small>
                        <hr/>
                      </div>
                    </span>
                  </ol>
                </span>
              </small>
            </span>
            <span
              v-else-if="
                props.column.field === 'form_tbbm.dm_river_boat.boat_name'
              "
            >
              <strong>
                <span
                  class="cursor-pointer"
                  @click="copyText(props.row.form_tbbm.dm_river_boat.boat_name)"
                >
                  {{ props.row.form_tbbm.dm_river_boat.boat_name }}
                </span>
                <div class="divider">
                  <div class="divider-text">Nomor PKK</div>
                </div>
                <!-- <hr /> -->
                <span>
                  <b-badge
                    href="#"
                    :variant="'light-primary'"
                    @click="copyText(props.row.form_tbbm.no_pkk)"
                    class="d-block"
                  >
                    {{ props.row.form_tbbm.no_pkk }}
                  </b-badge>
                </span>
                <div class="divider">
                  <div class="divider-text">Kegiatan</div>
                </div>

                <span>
                  <strong>
                    <b-badge
                      href="#"
                      :variant="
                        props.row.form_tbbm.kegiatan == 'BONGKAR'
                          ? 'light-primary'
                          : props.row.form_tbbm.kegiatan == 'TAMBAT'
                          ? 'light-danger'
                          : 'light-dark'
                      "
                      @click="
                        copyText(
                          `${props.row.form_tbbm.kegiatan}${
                            props.row.kegiatan_tambahan
                              ? `, ${props.row.kegiatan_tambahan}`
                              : ``
                          }`
                        )
                      "
                      class="d-block"
                    >
                      <span
                        v-text="
                          `${props.row.form_tbbm.kegiatan}${
                            props.row.kegiatan_tambahan
                              ? `, ${props.row.kegiatan_tambahan}`
                              : ``
                          }`
                        "
                      />
                    </b-badge>
                  </strong>
                </span>
              </strong>
            </span>
            <span v-else-if="props.column.field === 'keterangan'">
              <span>[{{ props.row.form_tbbm.jenis_kapal }}]&nbsp;</span>
              <div class="divider">
                <div class="divider-text">Pemilik</div>
              </div>
              <b-badge
                href="#"
                :variant="
                  props.row.form_tbbm.pemilik === 'TEM'
                    ? 'light-primary'
                    : 'light-primary'
                "
                @click="copyText(props.row.form_tbbm.pemilik)"
                class="d-block"
              >
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span>
                  {{ props.row.form_tbbm.pemilik }}
                  <span v-if="props.row.pemilik_dua">
                    , {{ props.row.pemilik_dua }}
                  </span>
                </span>
              </b-badge>
              <div class="divider">
                <div class="divider-text">
                  Approval
                  {{
                    props.row.form_tbbm.keterangan_batal_approval
                      ? 'Dibatalkan'
                      : ''
                  }}
                </div>
              </div>

              <b-badge
                href="#"
                :variant="
                  props.row.form_tbbm.no_do_bl
                    ? 'light-success'
                    : 'light-warning'
                "
                @click="
                  copyText(
                    props.row.form_tbbm.no_do_bl
                      ? 'telah disetujui'
                      : 'belum disetujui'
                  )
                "
                class="d-block"
              >
                <feather-icon
                  v-if="props.row.form_tbbm.no_do_bl"
                  icon="FileIcon"
                  class="mr-50"
                />
                <b-spinner
                  v-if="!props.row.form_tbbm.no_do_bl"
                  variant="warning"
                  class="mr-1"
                  type="grow"
                  small
                />
                <strong>
                  <span
                    v-text="
                      !props.row.form_tbbm.no_do_bl
                        ? props.row.form_tbbm.keterangan_batal_approval
                          ? props.row.form_tbbm.keterangan_batal_approval
                          : 'Belum disetujui'
                        : 'Telah disetujui'
                    "
                  />
                </strong>
                <b-spinner
                  v-if="!props.row.form_tbbm.no_do_bl"
                  variant="warning"
                  class="ml-1"
                  type="grow"
                  small
                />
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'form_tbbm.rencana_sandar'">
              <strong>
                <span>&nbsp;</span>
                <div class="divider">
                  <div class="divider-text">Rencana Sandar</div>
                </div>
                <b-badge
                  href="#"
                  :variant="
                    props.row.form_tbbm.pemilik === 'TEM'
                      ? 'light-primary'
                      : 'light-primary'
                  "
                  @click="
                    copyText(
                      dateHumanReadAble(props.row.form_tbbm.rencana_sandar)
                    )
                  "
                  class="d-block"
                >
                  <feather-icon icon="CalendarIcon" class="mr-50" />
                  {{ dateHumanReadAble(props.row.form_tbbm.rencana_sandar) }}
                  <!-- <feather-icon
                    class="cursor-pointer mr-1"
                    :icon="
                      dateHumanReadAble(
                        props.row.form_tbbm.rencana_sandar
                      ).includes('AM')
                        ? 'SunIcon'
                        : 'MoonIcon'
                    "
                  /> -->
                </b-badge>

                <div class="divider">
                  <div class="divider-text">Asal Kapal</div>
                </div>
                <b-badge
                  href="#"
                  :variant="
                    props.row.form_tbbm.pemilik === 'TEM'
                      ? 'light-primary'
                      : 'light-primary'
                  "
                  @click="
                    copyText(asalKapal(props.row.form_tbbm.dm_asal_kapal))
                  "
                  class="d-block"
                >
                  <feather-icon icon="MapPinIcon" class="mr-50" />
                  {{ asalKapal(props.row.form_tbbm.dm_asal_kapal) }}
                </b-badge>
              </strong>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  :variant="
                    props.row.form_tbbm.form_status !== 'KAPAL BERANGKAT'
                      ? props.row.form_tbbm.form_status ===
                        'KEGIATAN DIBATALKAN'
                        ? 'outline-danger'
                        : 'outline-warning'
                      : 'outline-success'
                  "
                  :to="{
                    name: enableViewForm,
                    params: {
                      id: props.row.id,
                      api: 'form_chain_tbbm',
                      kegiatanDetail: currentlyKegiatanKapal(
                        props.row.form_tbbm
                      ),
                      previous_link: {
                        name: 'dashboard-monitoring-rrv-tbbm'
                      }
                    }
                  }"
                  size="sm"
                  block
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="
                      props.row.form_tbbm.form_status ===
                      'NO PERIJINAN BELUM LENGKAP'
                        ? 'PenToolIcon'
                        : 'PenToolIcon'
                    "
                    size="25"
                  />
                  <!-- Realisasikan -->
                </b-button>
                <br />
                <b-button
                  class="mt-1"
                  variant="outline-primary"
                  block
                  size="sm"
                  :to="{
                    name: 'dashboard-monitoring-rrv-tbbm-summary',
                    params: {
                      id: props.row.id,
                      api: 'form_chain_tbbm',
                      previous_link: {
                        name: 'dashboard-monitoring-rrv-tbbm'
                      }
                    }
                  }"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="ActivityIcon"
                    size="25"
                  />
                  <!-- Summary -->
                </b-button>
                <br />
                <b-badge pill class="d-block" :variant="'light-primary'">
                  <strong>
                    <span> #{{ props.row.id }}</span>
                  </strong>
                </b-badge>
              </span>
            </span>
            <span v-else>
              <strong>{{ props.formattedRow[props.column.field] }}</strong>
            </span>
          </span>
        </transition>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'CompGoodTable',
  components: {
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr,
    BSpinner,
    BBadge
  },
  props: {
    resolveFetchResponse: Function,
    resolveFetchPayload: Function,
    dateHumanReadAble: Function,
    columns: Array,
    thereIsGroup: Boolean,
    enableCreateForm: String,
    enableViewForm: String
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotificationRiver } =
      useAppConfig()
    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  data() {
    return {
      isNoRpkro: false,
      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          type: 'DESC',
          field: 'created_at'
        },
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    /* isNoRpkro ?? */
    checkIsNoRpkro(){ /* props.row parameter */
      return (props) => {
        const { form_tbbm } = props
        const isBunker = form_tbbm.kegiatan
        const isLct = form_tbbm.jenis_kapal
        const no_do_bl = form_tbbm.no_do_bl
        const hardcodeJenisKapal = 'LCT / KM / MT / SPOB / Sea Truck'
        const hardcodeKegiatan = 'BUNKER'

        if(isBunker === hardcodeKegiatan && isLct === hardcodeJenisKapal){
          return true
        }
        return false
      }
    },
    rowStyleClassFn() {
      return (row) => {
        // console.log(row.form_tbbm_id)
        return 'custom-transition'
      }
    },
    showWarning() {
      return (props) => {
        try {
          if (this.$can('SAL', '')) {
            const {
              no_rpkro,
              no_spog,
              kegiatan_start_time_p1,
              kegiatan_end_time_p1
            } = props.form_tbbm

            if (
              !no_rpkro ||
              !no_spog ||
              !kegiatan_start_time_p1 ||
              !kegiatan_end_time_p1
            ) {
              return true
            } else {
              return false
            }
          } else {
            if (this.$can('TBBM', '')) {
              try {
                if (props.form_tbbm.no_do_bl) {
                  const { no_spog } = props.form_tbbm
                  if (!no_spog) {
                    try {
                      const { no_spog_tb } = props.form_tug_boat
                      if (!no_spog_tb) {
                        return true
                      }
                    } catch (error) {}
                    return true
                  } else {
                    return false
                  }
                }
              } catch (error) {
                // tug boat only
                const { no_spog } = props.form_tbbm
                if (!no_spog) {
                  try {
                    const { no_spog_tb } = props.form_tug_boat
                    if (!no_spog_tb) {
                      return true
                    }
                  } catch (error) {}
                  return true
                } else {
                  return false
                }
              }
            }
            return false
          }
        } catch (error) {
          return false
        }
      }
    },
    asalKapal() {
      return (props) => {
        if (props) {
          return props.asal
        } else {
          return '-'
        }
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formStatusVariant() {
      return (any) => this.$variantColumnFormStatus[any]
    },
    verifiedVariant() {
      return (any) => this.$variantVerified[any]
    },
    currentlyPosisiKapal() {
      return (props) => {
        let string
        const data = props.realisasi_posisi_kapals
        const length = data.length
        if (length !== 0) {
          const posisi = data[length - 1].posisi_skrg
          const baris = data[length - 1].posisi_skrg_baris
          string = `#POSISI_${posisi} #${baris}`
        } else {
          string = '#POSISI_'
        }
        return string
      }
    },
    currentlyKegiatanKapal() {
      return (props) => {
        return ''
      }
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotificationRiver() {
      if (!this.serverParams.searchTerm) {
        console.log(
          'anIncomingNotificationRiver ::',
          this.anIncomingNotificationRiver
        )
        let high_light_data =
          this.anIncomingNotificationRiver.data[
            `gcm.notification.screen_re_fetch`
          ]
        let will_be_refresh = ['form_tug_boat', 'form_chain_tbbm']
        let go_refresh = will_be_refresh.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh::', go_refresh)

        if (go_refresh) {
          this.fetchData(true, '')
        }
      }
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
    }
  },
  mounted() {
    // document.body.style.zoom = '100%'
    const isBack = !!this.$router.currentRoute.params?.reFetch
    console.log('mounted', isBack)
    if (isBack) {
      // this.fetchData(true, '')
    }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, '')
    console.log('this.$refs.goodTable::', this.$refs.goodTable)
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    /* START FROM HERE DONT MODIFIED */
    moment,
    ftbStatus(row) {
      const end = row.tug_sandar_end_time
      const start = row.tug_sandar_start_time
      if (row.is_cancel === true) {
        return 'KEGIATAN DIBATALKAN'
      } else {
        if (start && end) {
          return 'KAPAL BERANGKAT'
        }
        if (start) {
          return 'KAPAL SANDAR'
        }
      }
      return 'KAPAL RENCANA SANDAR'
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.rows = []
      this.total_records = 0
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(`onSortChange : ${JSON.stringify(params)}`)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    // #fetchData
    fetchData() {
      this.isTimerStart()
      const payload = this.resolveFetchPayload()
      const uri = payload.API_SAL

      this.serverParams = {
        ...this.serverParams
        // ...originalServerParams
      }

      useJwt.http
        .get(`${uri}`, {
          params: { ...this.serverParams,
            columnExcludes: {
              "form_tbbm.rencana_posisi": ["JETTY 3A"],
            }
           }
        })
        .then((res) => {
          const { total_records } = res.data
          this.total_records = total_records
          const get_rows = this.resolveFetchResponse(res).map((data) => ({
            ...data
          }))
          this.rows = get_rows

          useJwt.http
            .get(`form_tug_boat/show/tbbm`, {
              params: { ...this.serverParams,
                columnExcludes: {
                  "rencana_position_jetty": ["JETTY 3A"],
                }
               }
            })
            .then((res) => {
              const { form_tug_boats, total_records } = res.data
              this.total_records += total_records
              this.rows.push(...form_tug_boats)
              this.rows.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
              })
            })

          this.isTimerDone()
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;
            .vgt-global-search__input {
              border: none !important;
              .input__icon {
                font-weight: 700 !important;
              }
              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              tr.custom-transition {
                transition: opacity 0.5s ease;
              }
              tr.custom-transition-active,
              tr.custom-transition-leave-active {
                transition: opacity 0.5s ease;
              }
              tr.custom-transition-enter-from,
              tr.custom-transition-leave-to {
                opacity: 0;
              }

              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
